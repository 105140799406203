<template>
    <div>
        <v-card>
            <Breadcrumbs />
            <v-row style="padding: 0px 16px">
                <v-col cols="3" style="padding: 4px 10px">
                    <!-- PK副标的搜索 -->
                    <v-text-field
                        v-model="search"
                        outlined
                        dense
                        label="Search Permission"
                        single-line
                        hide-details
                    >
                    </v-text-field>
                </v-col>

                <v-col cols="5"></v-col>

                <v-col cols="4" class="text-right">
                    <v-btn
                        color="primary"
                        small
                        v-on:click="toggleDialog('add')"
                    >
                        add permission
                    </v-btn>
                </v-col>
            </v-row>

            <v-row>
                <v-col lg="12" md="12" xs="12" cols="9">
                    <v-row>
                        <v-col lg="12" md="12" xs="12" cols="12">
                            <v-data-table
                                dense
                                :search="search"
                                :headers="permissionHeader"
                                :items="permissionData"
                                item-key="id"
                                class="elevation-0"
                                :loading="loading"
                                :items-per-page="-1"
                                id="home-table"
                                :hide-default-footer="false"
                                fixed-header
                                sort-by="id"
                                :header-props="{ sortIcon: null }"
                            >
                                <template v-slot:item.action="{ item }">
                                    <v-tooltip bottom>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-icon
                                                v-bind="attrs"
                                                v-on="on"
                                                dense
                                                v-on:click="
                                                    toggleDialog('add', item)
                                                "
                                                >mdi-content-copy</v-icon
                                            >
                                        </template>
                                        <span>{{
                                            `Copy as ` + item.permission_name
                                        }}</span>
                                    </v-tooltip>

                                    {{ "" }}
                                    <v-icon
                                        dense
                                        v-on:click="
                                            toggleDialog('update', item)
                                        "
                                        >mdi-pen</v-icon
                                    >
                                    {{ "" }}
                                    <v-icon
                                        dense
                                        v-on:click="deleteConfirmation(item)"
                                        >mdi-delete</v-icon
                                    >
                                </template>
                            </v-data-table>
                        </v-col>

                        <v-dialog
                            v-model="dialogPermission.visible"
                            :fullscreen="dialogPermission.fullscreen"
                            width="1200"
                            :hide-overlay="true"
                            :persistent="true"
                        >
                            <v-card class="pt-0">
                                <v-app-bar
                                    flat
                                    color="rgba(0, 0, 0, 0)"
                                    style="background: rgb(26, 57, 88)"
                                >
                                    <v-toolbar-title
                                        class="text-h6 white--text pl-0"
                                        >{{
                                            dialogPermission.title
                                        }}</v-toolbar-title
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="white"
                                        icon
                                        @click="
                                            dialogPermission.fullscreen =
                                                !dialogPermission.fullscreen
                                        "
                                        ><v-icon
                                            >mdi-arrow-expand</v-icon
                                        ></v-btn
                                    >
                                    <v-btn
                                        color="white"
                                        icon
                                        @click="
                                            dialogPermission.visible = false
                                        "
                                        ><v-icon>mdi-close</v-icon></v-btn
                                    >
                                </v-app-bar>

                                <v-card-actions>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-text-field
                                                label="Permission Name"
                                                v-model="
                                                    dialogPermission.permission_name
                                                "
                                                hide-details
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="6"
                                            v-if="dialogPermission.id === -1"
                                        >
                                            <v-select
                                                label="Copy from (optional)"
                                                :items="permissionData"
                                                item-text="permission_name"
                                                item-value="permission"
                                                @change="
                                                    (e) => changeCopyFrom(e)
                                                "
                                                hide-details
                                            ></v-select>
                                        </v-col>

                                        <v-col cols="10" class="pb-0">
                                            <p style="margin: 0">Permission</p>
                                        </v-col>
                                        <v-col cols="2" class="pb-0">
                                            <!-- :disabled="isSwitchDisabled" -->
                                            <v-switch
                                                v-model="jsonView"
                                                @click="() => changeView()"
                                                :label="
                                                    jsonView
                                                        ? 'Toggle UI'
                                                        : 'Toggle Json'
                                                "
                                                hide-details
                                                style="margin: 0; padding: 0"
                                            ></v-switch>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            class="pd-0"
                                            v-if="jsonView"
                                        >
                                            <div
                                                style="
                                                    overflow: scroll;
                                                    height: 60vh;
                                                "
                                            >
                                                <JsonEditor
                                                    ref="jsonEditor"
                                                    v-model="
                                                        dialogPermission.permission
                                                    "
                                                    v-if="loadJson"
                                                />
                                            </div>
                                        </v-col>
                                        <v-col cols="12" class="pd-0" v-else>
                                            <v-expansion-panels>
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header>
                                                        Site Book Selection
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        <v-row
                                                            style="
                                                                padding: 0px
                                                                    25px;
                                                            "
                                                        >
                                                            <v-col
                                                                cols="12"
                                                                class="pt-0 pb-0"
                                                            >
                                                                <v-btn
                                                                    :color="
                                                                        bookSelection.length !==
                                                                        bookData.length
                                                                            ? 'primary'
                                                                            : 'warning'
                                                                    "
                                                                    style="
                                                                        width: 100%;
                                                                        height: 20px;
                                                                    "
                                                                    class="mb-0"
                                                                    dense
                                                                    hide-details
                                                                    @click="
                                                                        selectAll
                                                                    "
                                                                    >{{
                                                                        bookSelection.length !==
                                                                        bookData.length
                                                                            ? "Select"
                                                                            : "Deselect"
                                                                    }}
                                                                    All</v-btn
                                                                >
                                                            </v-col>
                                                            <v-col
                                                                cols="3"
                                                                v-for="book in bookData.filter(
                                                                    (item) =>
                                                                        item.accessible !==
                                                                        false
                                                                )"
                                                                :key="book.id"
                                                                style="
                                                                    padding-top: 0;
                                                                    padding-bottom: 5px;
                                                                "
                                                            >
                                                                <v-switch
                                                                    v-model="
                                                                        bookSelection
                                                                    "
                                                                    color="primary"
                                                                    :label="
                                                                        book.book_name
                                                                    "
                                                                    :value="
                                                                        book.book_name
                                                                    "
                                                                    dense
                                                                    hide-details
                                                                ></v-switch>
                                                            </v-col>
                                                        </v-row>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header>
                                                        Book Page
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        <v-row
                                                            style="
                                                                padding: 0px
                                                                    25px;
                                                            "
                                                            ><v-col cols="3"
                                                                ><v-checkbox
                                                                    v-model="
                                                                        homeLoginDetailSummary
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                >
                                                                    <template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "homeLoginDetailSummary"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "homeLoginDetailSummary"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox> </v-col
                                                            ><v-col cols="3"
                                                                ><v-checkbox
                                                                    v-model="
                                                                        mtdVolumeColumn
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                    ><template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "mtdVolumeColumn"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "mtdVolumeColumn"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox></v-col
                                                            ><v-col cols="3"
                                                                ><v-checkbox
                                                                    v-model="
                                                                        mtdPnlColumn
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                    ><template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "mtdPnlColumn"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "mtdPnlColumn"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox></v-col
                                                            >

                                                            <v-col cols="3"
                                                                ><v-checkbox
                                                                    v-model="
                                                                        loginSummaryInfo
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                    ><template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "loginSummaryInfo"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "loginSummaryInfo"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox></v-col
                                                            >

                                                            <v-col cols="3"
                                                                ><v-checkbox
                                                                    v-model="
                                                                        loginSummaryStatistic
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                    ><template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "loginSummaryStatistic"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "loginSummaryStatistic"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox></v-col
                                                            >

                                                            <v-col cols="3"
                                                                ><v-checkbox
                                                                    v-model="
                                                                        loginSummaryTrades
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                    ><template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "loginSummaryTrades"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "loginSummaryTrades"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox></v-col
                                                            >
                                                            <v-col cols="3"
                                                                ><v-checkbox
                                                                    v-model="
                                                                        extraSymbolDetails
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                >
                                                                    <template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "extraSymbolDetails"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "extraSymbolDetails"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox>
                                                            </v-col>
                                                            <v-col cols="3"
                                                                ><v-checkbox
                                                                    v-model="
                                                                        allowDownloadAll
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                >
                                                                    <template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "allowDownloadAll"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "allowDownloadAll"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox>
                                                            </v-col>
                                                            <v-col cols="3"
                                                                ><v-checkbox
                                                                    v-model="
                                                                        dailySummaryIcon
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                >
                                                                    <template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "dailySummaryIcon"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "dailySummaryIcon"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox>
                                                            </v-col>
                                                            <v-col cols="3"
                                                                ><v-checkbox
                                                                    v-model="
                                                                        symbolSnapshot
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                    ><template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "symbolSnapshot"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "symbolSnapshot"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox></v-col
                                                            >
                                                            <v-col cols="3"
                                                                ><v-checkbox
                                                                    v-model="
                                                                        loginSnapshot
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                    ><template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "loginSnapshot"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "loginSnapshot"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox></v-col
                                                            >
                                                            <v-col cols="3"
                                                                ><v-checkbox
                                                                    v-model="
                                                                        devSumUp
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                    ><template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "devSumUp"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "devSumUp"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox></v-col
                                                            >
                                                        </v-row>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header>
                                                        LP Page
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        <v-checkbox
                                                            v-model="enableLP"
                                                            hide-details
                                                            style="
                                                                margin: 0;
                                                                padding: 0;
                                                            "
                                                            ><template
                                                                v-slot:label
                                                            >
                                                                {{
                                                                    permissionSettingInfo[
                                                                        "enableLP"
                                                                    ].label
                                                                }}
                                                                <v-tooltip
                                                                    bottom
                                                                >
                                                                    <template
                                                                        v-slot:activator="{
                                                                            on,
                                                                            attrs,
                                                                        }"
                                                                    >
                                                                        <v-icon
                                                                            color="grey"
                                                                            dark
                                                                            v-bind="
                                                                                attrs
                                                                            "
                                                                            v-on="
                                                                                on
                                                                            "
                                                                            class="pl-1"
                                                                        >
                                                                            mdi-information
                                                                        </v-icon>
                                                                    </template>
                                                                    <span>{{
                                                                        permissionSettingInfo[
                                                                            "enableLP"
                                                                        ]
                                                                            .tooltip
                                                                    }}</span>
                                                                </v-tooltip>
                                                            </template>
                                                        </v-checkbox>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header>
                                                        Alert Page
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        <v-row
                                                            style="
                                                                padding: 0px
                                                                    25px;
                                                            "
                                                            ><v-col cols="3"
                                                                ><v-checkbox
                                                                    v-model="
                                                                        enableAlert
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                    ><template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "enableAlert"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "enableAlert"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox></v-col
                                                            >
                                                            <v-col cols="3">
                                                                <v-checkbox
                                                                    v-model="
                                                                        allowTickMutation
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                    ><template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "allowTickMutation"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "allowTickMutation"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox></v-col
                                                            >
                                                        </v-row>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header>
                                                        Search Page
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        <v-row
                                                            style="
                                                                padding: 0px
                                                                    25px;
                                                            "
                                                        >
                                                            <v-col cols="3">
                                                                <v-checkbox
                                                                    v-model="
                                                                        enableSearch
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                    ><template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "enableSearch"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "enableSearch"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox>
                                                            </v-col>
                                                            <v-col cols="3">
                                                                <v-checkbox
                                                                    v-model="
                                                                        allowDownloadRequest
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                    ><template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "allowDownloadRequest"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "allowDownloadRequest"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox>
                                                            </v-col>
                                                            <v-col cols="3">
                                                                <v-checkbox
                                                                    v-model="
                                                                        ibRelatedFields
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                    ><template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "ibRelatedFields"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "ibRelatedFields"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox>
                                                            </v-col>
                                                        </v-row>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header>
                                                        Settings Page
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        <v-row
                                                            style="
                                                                padding: 0px
                                                                    25px;
                                                            "
                                                            ><v-col cols="3"
                                                                ><v-checkbox
                                                                    v-model="
                                                                        alertConfig
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                    ><template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "alertConfig"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "alertConfig"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox></v-col
                                                            ><v-col cols="3"
                                                                ><v-checkbox
                                                                    v-model="
                                                                        brokerConfig
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                    ><template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "brokerConfig"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "brokerConfig"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox></v-col
                                                            ><v-col cols="3"
                                                                ><v-checkbox
                                                                    v-model="
                                                                        serverConfig
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                    ><template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "serverConfig"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "serverConfig"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox></v-col
                                                            ><v-col cols="3"
                                                                ><v-checkbox
                                                                    v-model="
                                                                        bookConfig
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                    ><template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "bookConfig"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "bookConfig"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox></v-col
                                                            >
                                                            <v-col cols="3"
                                                                ><v-checkbox
                                                                    v-model="
                                                                        pkTaker
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                    ><template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "pkTaker"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "pkTaker"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox></v-col
                                                            >
                                                            <v-col cols="3"
                                                                ><v-checkbox
                                                                    v-model="
                                                                        routingConfig
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                    ><template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "routingConfig"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "routingConfig"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox></v-col
                                                            >
                                                            <v-col cols="3"
                                                                ><v-checkbox
                                                                    v-model="
                                                                        copyTradeConfig
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                    ><template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "copyTradeConfig"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "copyTradeConfig"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox></v-col
                                                            >
                                                            <v-col cols="3"
                                                                ><v-checkbox
                                                                    v-model="
                                                                        lpConfig
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                    ><template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "lpConfig"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "lpConfig"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox></v-col
                                                            >
                                                            <v-col cols="3"
                                                                ><v-checkbox
                                                                    v-model="
                                                                        notificationConfig
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                    ><template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "notificationConfig"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "notificationConfig"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox></v-col
                                                            >
                                                            <v-col cols="3"
                                                                ><v-checkbox
                                                                    v-model="
                                                                        alertConfigEdit
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                    ><template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "alertConfigEdit"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "alertConfigEdit"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox></v-col
                                                            >
                                                        </v-row>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header>
                                                        Account Menu
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        <v-row
                                                            style="
                                                                padding: 0px
                                                                    25px;
                                                            "
                                                            ><v-col cols="3"
                                                                ><v-checkbox
                                                                    v-model="
                                                                        accountPermission
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                    ><template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "accountPermission"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "accountPermission"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox></v-col
                                                            ><v-col cols="3"
                                                                ><v-checkbox
                                                                    v-model="
                                                                        accountManagement
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                    ><template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "accountManagement"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "accountManagement"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox></v-col
                                                            ><v-col cols="3"
                                                                ><v-checkbox
                                                                    v-model="
                                                                        accountConfig
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                    ><template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "accountConfig"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "accountConfig"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox></v-col
                                                            ><v-col cols="3"
                                                                ><v-checkbox
                                                                    v-model="
                                                                        accountProfile
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                    ><template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "accountProfile"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "accountProfile"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox></v-col
                                                            ></v-row
                                                        >
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header>
                                                        K8S Page
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        <v-checkbox
                                                            v-model="enableK8s"
                                                            hide-details
                                                            style="
                                                                margin: 0;
                                                                padding: 0;
                                                            "
                                                            ><template
                                                                v-slot:label
                                                            >
                                                                {{
                                                                    permissionSettingInfo[
                                                                        "enableK8s"
                                                                    ].label
                                                                }}
                                                                <v-tooltip
                                                                    bottom
                                                                >
                                                                    <template
                                                                        v-slot:activator="{
                                                                            on,
                                                                            attrs,
                                                                        }"
                                                                    >
                                                                        <v-icon
                                                                            color="grey"
                                                                            dark
                                                                            v-bind="
                                                                                attrs
                                                                            "
                                                                            v-on="
                                                                                on
                                                                            "
                                                                            class="pl-1"
                                                                        >
                                                                            mdi-information
                                                                        </v-icon>
                                                                    </template>
                                                                    <span>{{
                                                                        permissionSettingInfo[
                                                                            "enableK8s"
                                                                        ]
                                                                            .tooltip
                                                                    }}</span>
                                                                </v-tooltip>
                                                            </template>
                                                        </v-checkbox>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header>
                                                        Copy Trade Page
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        <v-checkbox
                                                            v-model="
                                                                enableCopyTrade
                                                            "
                                                            hide-details
                                                            style="
                                                                margin: 0;
                                                                padding: 0;
                                                            "
                                                            ><template
                                                                v-slot:label
                                                            >
                                                                {{
                                                                    permissionSettingInfo[
                                                                        "enableCopyTrade"
                                                                    ].label
                                                                }}
                                                                <v-tooltip
                                                                    bottom
                                                                >
                                                                    <template
                                                                        v-slot:activator="{
                                                                            on,
                                                                            attrs,
                                                                        }"
                                                                    >
                                                                        <v-icon
                                                                            color="grey"
                                                                            dark
                                                                            v-bind="
                                                                                attrs
                                                                            "
                                                                            v-on="
                                                                                on
                                                                            "
                                                                            class="pl-1"
                                                                        >
                                                                            mdi-information
                                                                        </v-icon>
                                                                    </template>
                                                                    <span>{{
                                                                        permissionSettingInfo[
                                                                            "enableCopyTrade"
                                                                        ]
                                                                            .tooltip
                                                                    }}</span>
                                                                </v-tooltip>
                                                            </template>
                                                        </v-checkbox>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header>
                                                        IB Monitor Page
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        <v-row
                                                            style="
                                                                padding: 0px
                                                                    25px;
                                                            "
                                                        >
                                                            <v-col cols="3"
                                                                ><v-checkbox
                                                                    v-model="
                                                                        enableIBMonitor
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                    ><template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "enableIBMonitor"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "enableIBMonitor"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox></v-col
                                                            >
                                                            <v-col cols="3"
                                                                ><v-checkbox
                                                                    v-model="
                                                                        ibLoginDetailSummary
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                >
                                                                    <template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "ibLoginDetailSummary"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "ibLoginDetailSummary"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox>
                                                            </v-col>
                                                            <v-col cols="3"
                                                                ><v-checkbox
                                                                    v-model="
                                                                        ibLoginSummaryInfo
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                    ><template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "ibLoginSummaryInfo"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "ibLoginSummaryInfo"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox></v-col
                                                            >
                                                            <v-col cols="3"
                                                                ><v-checkbox
                                                                    v-model="
                                                                        ibLoginSummaryStatistic
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                    ><template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "ibLoginSummaryStatistic"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "ibLoginSummaryStatistic"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox></v-col
                                                            >
                                                            <v-col cols="3"
                                                                ><v-checkbox
                                                                    v-model="
                                                                        ibLoginSummaryTrades
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                    ><template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "ibLoginSummaryTrades"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "ibLoginSummaryTrades"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox></v-col
                                                            >
                                                            <v-col cols="3"
                                                                ><v-checkbox
                                                                    v-model="
                                                                        ibExtraSymbolDetails
                                                                    "
                                                                    hide-details
                                                                    style="
                                                                        margin: 0;
                                                                        padding: 0;
                                                                    "
                                                                >
                                                                    <template
                                                                        v-slot:label
                                                                    >
                                                                        {{
                                                                            permissionSettingInfo[
                                                                                "ibExtraSymbolDetails"
                                                                            ]
                                                                                .label
                                                                        }}
                                                                        <v-tooltip
                                                                            bottom
                                                                        >
                                                                            <template
                                                                                v-slot:activator="{
                                                                                    on,
                                                                                    attrs,
                                                                                }"
                                                                            >
                                                                                <v-icon
                                                                                    color="grey"
                                                                                    dark
                                                                                    v-bind="
                                                                                        attrs
                                                                                    "
                                                                                    v-on="
                                                                                        on
                                                                                    "
                                                                                    class="pl-1"
                                                                                >
                                                                                    mdi-information
                                                                                </v-icon>
                                                                            </template>
                                                                            <span
                                                                                >{{
                                                                                    permissionSettingInfo[
                                                                                        "ibExtraSymbolDetails"
                                                                                    ]
                                                                                        .tooltip
                                                                                }}</span
                                                                            >
                                                                        </v-tooltip>
                                                                    </template>
                                                                </v-checkbox>
                                                            </v-col>
                                                        </v-row>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                            </v-expansion-panels>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="green darken-1"
                                        text
                                        @click="
                                            dialogPermission.visible = false
                                        "
                                        :disabled="loading"
                                        >Cancel</v-btn
                                    >
                                    <v-btn
                                        color="green darken-1"
                                        v-if="
                                            dialogPermission.type === 'update'
                                        "
                                        text
                                        @click="updatePermission()"
                                        :loading="loading"
                                        ><span>Update</span></v-btn
                                    >
                                    <v-btn
                                        color="green darken-1"
                                        v-if="dialogPermission.type === 'add'"
                                        text
                                        @click="createPermission()"
                                        :loading="loading"
                                        ><span>Add</span></v-btn
                                    >
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
        <DeleteConfirmDialog
            :confirmPopover="popup"
            type="Permission"
            :name="selectedDeletion.permission_name"
            :loading="loading"
            @close="
                () => {
                    popup = false;
                }
            "
            @confirm="deleteConfig"
        ></DeleteConfirmDialog>
    </div>
</template>
<script>
import { snackbar } from "@components/mixins/snackbar";
import { permission } from "@components/mixins/permission";
import { mapState, mapActions } from "vuex";
import Breadcrumbs from "@components/Breadcrumbs";
import JsonEditor from "@components/ComponentJsonEditor";
import validator from "@assets/js/validator";
import DeleteConfirmDialog from "@components/ComponentDeleteConfirmDialog";

export default {
    components: { Breadcrumbs, JsonEditor, DeleteConfirmDialog },
    mixins: [snackbar, permission],
    data() {
        return {
            search: "",
            selectedConfigId: null,
            showEditAlert: 0,
            showEditPermission: 0,
            permissionLoadJson: true,
            selectedPermission: [],
            selectedRole: "",
            permissionConfig: [],
            selectedConfig: "alert",
            alertName:
                "volume_alert, big_lot, mandate, profit_taker, locking_position, trade_on_credit, large_exposure, fast_trade, watch_list, tick_check, withdraw_deposit",
            btnLoading: false,
            dialogPermission: {
                visible: false,
                id: -1,
                permission_name: "",
                permission: null,
                fullscreen: false,
                type: "",
                title: "",
            },
            loadJson: false,
            popup: false,
            selectedDeletion: {},
            jsonView: false,

            // below are the settings for permission
            // book
            bookSelection: [],
            // home
            homeLoginDetailSummary: false,
            extraSymbolDetails: false,
            allowDownloadAll: false,
            dailySummaryIcon: false,
            mtdVolumeColumn: false,
            mtdPnlColumn: false,
            loginSummaryInfo: false,
            loginSummaryStatistic: false,
            loginSummaryTrades: false,
            devSumUp: false,
            symbolSnapshot: false,
            loginSnapshot: false,
            // lp
            enableLP: false,
            //search:
            enableSearch: false,
            allowDownloadRequest: false,
            ibRelatedFields: false,
            // alert
            enableAlert: false,
            allowTickMutation: false,
            // settings
            alertConfig: false,
            brokerConfig: false,
            serverConfig: false,
            bookConfig: false,
            pkTaker: false,
            routingConfig: false,
            copyTradeConfig: false,
            alertConfigEdit: false,
            lpConfig: false,
            notificationConfig: false,
            // k8s
            enableK8s: false,
            //copy trade
            enableCopyTrade: false,
            // IB Monitor
            enableIBMonitor: false,
            ibExtraSymbolDetails: false,
            ibLoginSummaryInfo: false,
            ibLoginSummaryStatistic: false,
            ibLoginSummaryTrades: false,
            ibLoginDetailSummary: false,
            // account
            accountProfile: false,
            accountConfig: false,
            accountPermission: false,
            accountManagement: false,
        };
    },
    watch: {
        permissionData(nv, ov) {
            this.dialogPermission.visible = false;
            this.popup = false;
            const permissionId = this.$route.query.permission_id;
            if (permissionId !== undefined) {
                const item = this.permissionData.filter(
                    (item) => item.id == permissionId
                )[0];
                this.toggleDialog("update", item);
            }
        },
        jsonView(nv) {
            if (nv === false) {
                this.updateCheckboxStatus(this.dialogPermission.permission);
            }
        },
        homeLoginDetailSummary(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.book
                        ?.function
                ) {
                    this.dialogPermission.permission.frontPermission.book.function =
                        this.dialogPermission.permission.frontPermission.book.function.filter(
                            (item) => item !== "homeLoginDetailSummary"
                        );
                }
            }
        },
        ibLoginDetailSummary(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.ibMonitor
                        ?.function
                ) {
                    this.dialogPermission.permission.frontPermission.ibMonitor.function =
                        this.dialogPermission.permission.frontPermission.ibMonitor.function.filter(
                            (item) => item !== "ibLoginDetailSummary"
                        );
                }
            }
        },
        extraSymbolDetails(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.book
                        ?.function
                ) {
                    this.dialogPermission.permission.frontPermission.book.function =
                        this.dialogPermission.permission.frontPermission.book.function.filter(
                            (item) => item !== "extraSymbolDetails"
                        );
                }
            }
        },
        allowDownloadAll(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.book
                        ?.function
                ) {
                    this.dialogPermission.permission.frontPermission.book.function =
                        this.dialogPermission.permission.frontPermission.book.function.filter(
                            (item) => item !== "allowDownloadAll"
                        );
                }
            }
        },
        ibExtraSymbolDetails(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.ibMonitor
                        ?.function
                ) {
                    this.dialogPermission.permission.frontPermission.ibMonitor.function =
                        this.dialogPermission.permission.frontPermission.ibMonitor.function.filter(
                            (item) => item !== "ibExtraSymbolDetails"
                        );
                }
            }
        },
        dailySummaryIcon(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.book
                        ?.function
                ) {
                    this.dialogPermission.permission.frontPermission.book.function =
                        this.dialogPermission.permission.frontPermission.book.function.filter(
                            (item) => item !== "dailySummaryIcon"
                        );
                }
            }
        },
        mtdVolumeColumn(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.book
                        ?.function
                ) {
                    this.dialogPermission.permission.frontPermission.book.function =
                        this.dialogPermission.permission.frontPermission.book.function.filter(
                            (item) => item !== "mtdVolumeColumn"
                        );
                }
            }
        },
        mtdPnlColumn(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.book
                        ?.function
                ) {
                    this.dialogPermission.permission.frontPermission.book.function =
                        this.dialogPermission.permission.frontPermission.book.function.filter(
                            (item) => item !== "mtdPnlColumn"
                        );
                }
            }
        },
        loginSummaryInfo(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.book
                        ?.function
                ) {
                    this.dialogPermission.permission.frontPermission.book.function =
                        this.dialogPermission.permission.frontPermission.book.function.filter(
                            (item) => item !== "loginSummaryInfo"
                        );
                }
            }
        },
        ibLoginSummaryInfo(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.ibMonitor
                        ?.function
                ) {
                    this.dialogPermission.permission.frontPermission.ibMonitor.function =
                        this.dialogPermission.permission.frontPermission.ibMonitor.function.filter(
                            (item) => item !== "ibLoginSummaryInfo"
                        );
                }
            }
        },
        loginSummaryStatistic(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.book
                        ?.function
                ) {
                    this.dialogPermission.permission.frontPermission.book.function =
                        this.dialogPermission.permission.frontPermission.book.function.filter(
                            (item) => item !== "loginSummaryStatistic"
                        );
                }
            }
        },
        ibLoginSummaryStatistic(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.ibMonitor
                        ?.function
                ) {
                    this.dialogPermission.permission.frontPermission.ibMonitor.function =
                        this.dialogPermission.permission.frontPermission.ibMonitor.function.filter(
                            (item) => item !== "ibLoginSummaryStatistic"
                        );
                }
            }
        },
        loginSummaryTrades(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.book
                        ?.function
                ) {
                    this.dialogPermission.permission.frontPermission.book.function =
                        this.dialogPermission.permission.frontPermission.book.function.filter(
                            (item) => item !== "loginSummaryTrades"
                        );
                }
            }
        },
        devSumUp(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.book
                        ?.function
                ) {
                    this.dialogPermission.permission.frontPermission.book.function =
                        this.dialogPermission.permission.frontPermission.book.function.filter(
                            (item) => item !== "devSumUp"
                        );
                }
            }
        },
        loginSnapshot(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.book
                        ?.function
                ) {
                    this.dialogPermission.permission.frontPermission.book.function =
                        this.dialogPermission.permission.frontPermission.book.function.filter(
                            (item) => item !== "loginSnapshot"
                        );
                }
            }
        },
        symbolSnapshot(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.book
                        ?.function
                ) {
                    this.dialogPermission.permission.frontPermission.book.function =
                        this.dialogPermission.permission.frontPermission.book.function.filter(
                            (item) => item !== "symbolSnapshot"
                        );
                }
            }
        },
        ibLoginSummaryTrades(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.ibMonitor
                        ?.function
                ) {
                    this.dialogPermission.permission.frontPermission.ibMonitor.function =
                        this.dialogPermission.permission.frontPermission.ibMonitor.function.filter(
                            (item) => item !== "ibLoginSummaryTrades"
                        );
                }
            }
        },
        enableLP(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (this.dialogPermission.permission?.frontPermission?.lp) {
                    delete this.dialogPermission.permission.frontPermission.lp;
                }
            }
        },
        enableSearch(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (this.dialogPermission.permission?.frontPermission?.search) {
                    delete this.dialogPermission.permission.frontPermission
                        .search;
                }
            }
        },
        allowDownloadRequest(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.search
                        ?.child
                ) {
                    this.dialogPermission.permission.frontPermission.search.child =
                        this.dialogPermission.permission.frontPermission.search.child.filter(
                            (item) => item !== "allowDownloadRequest"
                        );
                }
            }
        },
        ibRelatedFields(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.search
                        ?.child
                ) {
                    this.dialogPermission.permission.frontPermission.search.child =
                        this.dialogPermission.permission.frontPermission.search.child.filter(
                            (item) => item !== "ibRelatedFields"
                        );
                }
            }
        },
        enableAlert(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.alert
                        ?.child
                ) {
                    this.dialogPermission.permission.frontPermission.alert.child =
                        this.dialogPermission.permission?.frontPermission?.alert.child.filter(
                            (item) => item !== "overview"
                        );
                }
            }
        },
        allowTickMutation(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.alert
                        ?.child
                ) {
                    this.dialogPermission.permission.frontPermission.alert.child =
                        this.dialogPermission.permission?.frontPermission?.alert.child.filter(
                            (item) => item !== "tickMutation"
                        );
                }
            }
        },
        alertConfig(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.settings
                        ?.child
                ) {
                    this.dialogPermission.permission.frontPermission.settings.child =
                        this.dialogPermission.permission.frontPermission.settings.child.filter(
                            (item) => item !== "alertConfig"
                        );
                }
            }
        },
        brokerConfig(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.settings
                        ?.child
                ) {
                    this.dialogPermission.permission.frontPermission.settings.child =
                        this.dialogPermission.permission.frontPermission.settings.child.filter(
                            (item) => item !== "brokerConfig"
                        );
                }
            }
        },
        serverConfig(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.settings
                        ?.child
                ) {
                    this.dialogPermission.permission.frontPermission.settings.child =
                        this.dialogPermission.permission.frontPermission.settings.child.filter(
                            (item) => item !== "serverConfig"
                        );
                }
            }
        },
        bookConfig(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.settings
                        ?.child
                ) {
                    this.dialogPermission.permission.frontPermission.settings.child =
                        this.dialogPermission.permission.frontPermission.settings.child.filter(
                            (item) => item !== "bookConfig"
                        );
                }
            }
        },
        pkTaker(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.settings
                        ?.child
                ) {
                    this.dialogPermission.permission.frontPermission.settings.child =
                        this.dialogPermission.permission.frontPermission.settings.child.filter(
                            (item) => item !== "pkTaker"
                        );
                }
            }
        },
        bookSelection(nv) {
            if (nv) {
                this.checkStructure();
            }
        },
        routingConfig(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.settings
                        ?.child
                ) {
                    this.dialogPermission.permission.frontPermission.settings.child =
                        this.dialogPermission.permission.frontPermission.settings.child.filter(
                            (item) => item !== "routingConfig"
                        );
                }
            }
        },
        copyTradeConfig(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.settings
                        ?.child
                ) {
                    this.dialogPermission.permission.frontPermission.settings.child =
                        this.dialogPermission.permission.frontPermission.settings.child.filter(
                            (item) => item !== "copyTradeConfig"
                        );
                }
            }
        },
        alertConfigEdit(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.settings
                        ?.function
                ) {
                    this.dialogPermission.permission.frontPermission.settings.function =
                        this.dialogPermission.permission.frontPermission.settings.function.filter(
                            (item) => item !== "alertConfigEdit"
                        );
                }
            }
        },
        lpConfig(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.settings
                        ?.child
                ) {
                    this.dialogPermission.permission.frontPermission.settings.child =
                        this.dialogPermission.permission.frontPermission.settings.child.filter(
                            (item) => item !== "lpConfig"
                        );
                }
            }
        },
        notificationConfig(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.settings
                        ?.child
                ) {
                    this.dialogPermission.permission.frontPermission.settings.child =
                        this.dialogPermission.permission.frontPermission.settings.child.filter(
                            (item) => item !== "notificationConfig"
                        );
                }
            }
        },
        enableK8s(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (this.dialogPermission.permission?.frontPermission?.k8s) {
                    delete this.dialogPermission.permission.frontPermission.k8s;
                }
            }
        },
        enableCopyTrade(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.copyTrade
                ) {
                    delete this.dialogPermission.permission.frontPermission
                        .copyTrade;
                }
            }
        },
        enableIBMonitor(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.ibMonitor
                        ?.child
                ) {
                    delete this.dialogPermission.permission.frontPermission
                        .ibMonitor.child;
                }
            }
        },
        accountProfile(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.account
                        ?.child
                ) {
                    this.dialogPermission.permission.frontPermission.account.child =
                        this.dialogPermission.permission.frontPermission.account.child.filter(
                            (item) => item !== "profile"
                        );
                }
            }
        },
        accountConfig(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.account
                        ?.child
                ) {
                    this.dialogPermission.permission.frontPermission.account.child =
                        this.dialogPermission.permission.frontPermission.account.child.filter(
                            (item) => item !== "config"
                        );
                }
            }
        },
        accountPermission(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.account
                        ?.child
                ) {
                    this.dialogPermission.permission.frontPermission.account.child =
                        this.dialogPermission.permission.frontPermission.account.child.filter(
                            (item) => item !== "permission"
                        );
                }
            }
        },
        accountManagement(nv) {
            if (nv) {
                this.checkStructure();
            } else {
                if (
                    this.dialogPermission.permission?.frontPermission?.account
                        ?.child
                ) {
                    this.dialogPermission.permission.frontPermission.account.child =
                        this.dialogPermission.permission.frontPermission.account.child.filter(
                            (item) => item !== "management"
                        );
                }
            }
        },
    },
    computed: {
        ...mapState("SitePermissionConfig", [
            "permissionData",
            "permissionHeader",
            "loading",
            "permissionSettingInfo",
        ]),
        ...mapState("SettingsBooks", ["bookData"]),
        isSwitchDisabled() {
            return !this.isPermissionValidJson();
        },
    },
    methods: {
        ...mapActions("SitePermissionConfig", [
            "getPermissionAction",
            "postPermissionAction",
            "putPermissionAction",
            "deletePermissionAction",
        ]),
        ...mapActions("SettingsBooks", ["getBooksAction"]),
        changeCopyFrom(settings) {
            this.dialogPermission.permission = { ...settings };
            this.updateCheckboxStatus({ ...settings });
        },
        /**
         * Used to edit or add permission
         * @return  {[type]}  [return description]
         */
        toggleDialog(type, data) {
            this.dialogPermission.visible = true;
            this.dialogPermission.type = type;
            this.jsonView = false;

            if (type === "add") {
                // add new permission
                this.dialogPermission.title = "Add New Permission";
                this.dialogPermission.permission_name = "";

                if (!!data) {
                    this.dialogPermission.id = -2;
                    this.changeCopyFrom(data.permission);
                } else {
                    this.dialogPermission.id = -1;
                    this.dialogPermission.permission = {};

                    this.homeLoginDetailSummary = false;
                    this.ibLoginDetailSummary = false;
                    this.extraSymbolDetails = false;
                    this.allowDownloadAll = false;
                    this.ibExtraSymbolDetails = false;
                    this.dailySummaryIcon = false;
                    this.mtdVolumeColumn = false;
                    this.mtdPnlColumn = false;
                    this.loginSummaryInfo = false;
                    this.ibLoginSummaryInfo = false;
                    this.loginSummaryStatistic = false;
                    this.ibLoginSummaryStatistic = false;
                    this.loginSummaryTrades = false;
                    this.devSumUp = false;
                    this.loginSnapshot = false;
                    this.symbolSnapshot = false;
                    this.ibLoginSummaryTrades = false;
                    this.enableLP = false;
                    this.alertConfig = false;
                    this.brokerConfig = false;
                    this.serverConfig = false;
                    this.bookConfig = false;
                    this.pkTaker = false;
                    this.routingConfig = false;
                    this.copyTradeConfig = false;
                    this.alertConfigEdit = false;
                    this.lpConfig = false;
                    this.notificationConfig = false;
                    this.enableSearch = false;
                    this.allowDownloadRequest = false;
                    this.ibRelatedFields = false;
                    this.enableAlert = false;
                    this.allowTickMutation = false;
                    this.enableK8s = false;
                    this.enableCopyTrade = false;
                    this.enableIBMonitor = false;
                    this.accountProfile = false;
                    this.accountConfig = false;
                    this.accountPermission = false;
                    this.accountManagement = false;
                    this.bookSelection = [];
                }
            } else {
                // update existing permission
                this.dialogPermission.title = "Update Permission";
                this.dialogPermission.permission_name = data.permission_name;
                this.dialogPermission.id = data.id;
                this.dialogPermission.permission = data.permission;

                this.updateCheckboxStatus(data.permission);
            }

            this.loadJson = false;
            this.$nextTick(() => {
                this.loadJson = true;
            });
        },
        updateCheckboxStatus(permission) {
            //book
            this.homeLoginDetailSummary =
                permission.frontPermission?.book?.function?.includes(
                    "homeLoginDetailSummary"
                )
                    ? true
                    : false;
            this.extraSymbolDetails =
                permission.frontPermission?.book?.function?.includes(
                    "extraSymbolDetails"
                )
                    ? true
                    : false;
            this.allowDownloadAll =
                permission.frontPermission?.book?.function?.includes(
                    "allowDownloadAll"
                )
                    ? true
                    : false;
            this.dailySummaryIcon =
                permission.frontPermission?.book?.function?.includes(
                    "dailySummaryIcon"
                )
                    ? true
                    : false;
            this.mtdVolumeColumn =
                permission.frontPermission?.book?.function?.includes(
                    "mtdVolumeColumn"
                )
                    ? true
                    : false;
            this.mtdPnlColumn =
                permission.frontPermission?.book?.function?.includes(
                    "mtdPnlColumn"
                )
                    ? true
                    : false;
            this.loginSummaryInfo =
                permission.frontPermission?.book?.function?.includes(
                    "loginSummaryInfo"
                )
                    ? true
                    : false;
            this.loginSummaryStatistic =
                permission.frontPermission?.book?.function?.includes(
                    "loginSummaryStatistic"
                )
                    ? true
                    : false;
            this.loginSummaryTrades =
                permission.frontPermission?.book?.function?.includes(
                    "loginSummaryTrades"
                )
                    ? true
                    : false;
            this.devSumUp =
                permission.frontPermission?.book?.function?.includes("devSumUp")
                    ? true
                    : false;
            this.loginSnapshot =
                permission.frontPermission?.book?.function?.includes(
                    "loginSnapshot"
                )
                    ? true
                    : false;
            this.symbolSnapshot =
                permission.frontPermission?.book?.function?.includes(
                    "symbolSnapshot"
                )
                    ? true
                    : false;
            //lp
            this.enableLP = permission.frontPermission?.lp?.child
                ? true
                : false;
            //search
            this.enableSearch = permission.frontPermission?.search?.child
                ? true
                : false;
            this.allowDownloadRequest =
                permission.frontPermission?.search?.child?.includes(
                    "allowDownloadRequest"
                )
                    ? true
                    : false;
            this.ibRelatedFields =
                permission.frontPermission?.search?.child?.includes(
                    "ibRelatedFields"
                )
                    ? true
                    : false;

            //alert
            this.enableAlert =
                permission.frontPermission?.alert?.child?.includes("overview")
                    ? true
                    : false;
            this.allowTickMutation =
                permission.frontPermission?.alert?.child?.includes(
                    "tickMutation"
                )
                    ? true
                    : false;

            //settings
            this.alertConfig =
                permission.frontPermission?.settings?.child?.includes(
                    "alertConfig"
                )
                    ? true
                    : false;
            this.brokerConfig =
                permission.frontPermission?.settings?.child?.includes(
                    "brokerConfig"
                )
                    ? true
                    : false;
            this.serverConfig =
                permission.frontPermission?.settings?.child?.includes(
                    "serverConfig"
                )
                    ? true
                    : false;
            this.bookConfig =
                permission.frontPermission?.settings?.child?.includes(
                    "bookConfig"
                )
                    ? true
                    : false;
            this.pkTaker =
                permission.frontPermission?.settings?.child?.includes("pkTaker")
                    ? true
                    : false;
            this.routingConfig =
                permission.frontPermission?.settings?.child?.includes(
                    "routingConfig"
                )
                    ? true
                    : false;
            this.copyTradeConfig =
                permission.frontPermission?.settings?.child?.includes(
                    "copyTradeConfig"
                )
                    ? true
                    : false;
            this.alertConfigEdit =
                permission.frontPermission?.settings?.function?.includes(
                    "alertConfigEdit"
                )
                    ? true
                    : false;
            this.lpConfig =
                permission.frontPermission?.settings?.child?.includes(
                    "lpConfig"
                )
                    ? true
                    : false;
            this.notificationConfig =
                permission.frontPermission?.settings?.child?.includes(
                    "notificationConfig"
                )
                    ? true
                    : false;

            //k8s
            this.enableK8s = permission.frontPermission?.k8s?.child?.includes(
                "index"
            )
                ? true
                : false;
            //Copy Trade
            this.enableCopyTrade =
                permission.frontPermission?.copyTrade?.child?.includes("index")
                    ? true
                    : false;
            //IB Monitor
            this.enableIBMonitor =
                permission.frontPermission?.ibMonitor?.child?.includes("index")
                    ? true
                    : false;
            this.ibLoginDetailSummary =
                permission.frontPermission?.ibMonitor?.function?.includes(
                    "ibLoginDetailSummary"
                )
                    ? true
                    : false;
            this.ibExtraSymbolDetails =
                permission.frontPermission?.ibMonitor?.function?.includes(
                    "ibExtraSymbolDetails"
                )
                    ? true
                    : false;
            this.ibLoginSummaryInfo =
                permission.frontPermission?.ibMonitor?.function?.includes(
                    "ibLoginSummaryInfo"
                )
                    ? true
                    : false;
            this.ibLoginSummaryStatistic =
                permission.frontPermission?.ibMonitor?.function?.includes(
                    "ibLoginSummaryStatistic"
                )
                    ? true
                    : false;
            this.ibLoginSummaryTrades =
                permission.frontPermission?.ibMonitor?.function?.includes(
                    "ibLoginSummaryTrades"
                )
                    ? true
                    : false;
            //account
            this.accountProfile =
                permission.frontPermission?.account?.child?.includes("profile")
                    ? true
                    : false;
            this.accountConfig =
                permission.frontPermission?.account?.child?.includes("config")
                    ? true
                    : false;
            this.accountPermission =
                permission.frontPermission?.account?.child?.includes(
                    "permission"
                )
                    ? true
                    : false;
            this.accountManagement =
                permission.frontPermission?.account?.child?.includes(
                    "management"
                )
                    ? true
                    : false;
            this.bookSelection =
                permission.frontPermission?.bookSelection || [];
        },
        checkStructure() {
            if (typeof this.dialogPermission.permission !== "object") {
                this.dialogPermission.permission = {
                    frontPermission: {
                        book: {
                            child: [],
                        },
                    },
                };
            }
            if (
                !this.dialogPermission.permission.frontPermission ||
                typeof this.dialogPermission.permission.frontPermission !==
                    "object"
            ) {
                this.dialogPermission.permission["frontPermission"] = {
                    book: {
                        child: [],
                        function: [],
                    },
                };
            }
            if (
                !this.dialogPermission.permission.frontPermission.book ||
                typeof this.dialogPermission.permission.frontPermission.book !==
                    "object"
            ) {
                this.dialogPermission.permission.frontPermission["book"] = {
                    child: [],
                    function: [],
                };
            }
            if (
                !this.dialogPermission.permission.frontPermission.book.child ||
                typeof this.dialogPermission.permission.frontPermission.book
                    .child !== "object"
            ) {
                this.dialogPermission.permission.frontPermission.book["child"] =
                    [];
            }
            if (
                !this.dialogPermission.permission.frontPermission.book
                    .function ||
                typeof this.dialogPermission.permission.frontPermission.book
                    .function !== "object"
            ) {
                this.dialogPermission.permission.frontPermission.book[
                    "function"
                ] = [];
            }
            if (
                this.homeLoginDetailSummary ||
                this.extraSymbolDetails ||
                this.allowDownloadAll ||
                this.dailySummaryIcon ||
                this.mtdVolumeColumn ||
                this.mtdPnlColumn ||
                this.loginSummaryInfo ||
                this.loginSummaryStatistic ||
                this.loginSummaryTrades ||
                this.devSumUp ||
                this.symbolSnapshot ||
                this.loginSnapshot
            ) {
                this.dialogPermission.permission.frontPermission.book[
                    "function"
                ] = [];
            }
            if (this.homeLoginDetailSummary) {
                this.dialogPermission.permission.frontPermission.book.function.push(
                    "homeLoginDetailSummary"
                );
            }
            if (this.extraSymbolDetails) {
                this.dialogPermission.permission.frontPermission.book.function.push(
                    "extraSymbolDetails"
                );
            }
            if (this.allowDownloadAll) {
                this.dialogPermission.permission.frontPermission.book.function.push(
                    "allowDownloadAll"
                );
            }
            if (this.dailySummaryIcon) {
                this.dialogPermission.permission.frontPermission.book.function.push(
                    "dailySummaryIcon"
                );
            }
            if (this.mtdVolumeColumn) {
                this.dialogPermission.permission.frontPermission.book.function.push(
                    "mtdVolumeColumn"
                );
            }
            if (this.mtdPnlColumn) {
                this.dialogPermission.permission.frontPermission.book.function.push(
                    "mtdPnlColumn"
                );
            }
            if (this.loginSummaryInfo) {
                this.dialogPermission.permission.frontPermission.book.function.push(
                    "loginSummaryInfo"
                );
            }
            if (this.loginSummaryStatistic) {
                this.dialogPermission.permission.frontPermission.book.function.push(
                    "loginSummaryStatistic"
                );
            }
            if (this.loginSummaryTrades) {
                this.dialogPermission.permission.frontPermission.book.function.push(
                    "loginSummaryTrades"
                );
            }
            if (this.devSumUp) {
                this.dialogPermission.permission.frontPermission.book.function.push(
                    "devSumUp"
                );
            }
            if (this.symbolSnapshot) {
                this.dialogPermission.permission.frontPermission.book.function.push(
                    "symbolSnapshot"
                );
            }
            if (this.loginSnapshot) {
                this.dialogPermission.permission.frontPermission.book.function.push(
                    "loginSnapshot"
                );
            }
            if (this.enableLP) {
                this.dialogPermission.permission.frontPermission["lp"] = {
                    child: [],
                };
            }
            if (this.enableSearch) {
                this.dialogPermission.permission.frontPermission["search"] = {
                    child: [],
                };
            }
            if (this.allowDownloadRequest) {
                this.dialogPermission.permission.frontPermission["search"] = {
                    child: [],
                };
            }
            if (this.ibRelatedFields) {
                this.dialogPermission.permission.frontPermission["search"] = {
                    child: [],
                };
            }
            if (this.allowDownloadRequest) {
                this.dialogPermission.permission.frontPermission.search.child.push(
                    "allowDownloadRequest"
                );
            }
            if (this.ibRelatedFields) {
                this.dialogPermission.permission.frontPermission.search.child.push(
                    "ibRelatedFields"
                );
            }
            if (this.enableAlert) {
                this.dialogPermission.permission.frontPermission["alert"] = {
                    child: [],
                };
            }
            if (this.allowTickMutation) {
                this.dialogPermission.permission.frontPermission["alert"] = {
                    child: [],
                };
            }
            if (this.enableAlert) {
                this.dialogPermission.permission.frontPermission[
                    "alert"
                ].child.push("overview");
            }
            if (this.allowTickMutation) {
                this.dialogPermission.permission.frontPermission[
                    "alert"
                ].child.push("tickMutation");
            }
            if (
                this.accountProfile ||
                this.accountConfig ||
                this.accountPermission ||
                this.accountManagement
            ) {
                this.dialogPermission.permission.frontPermission["account"] = {
                    child: [],
                };
            }
            if (this.accountProfile) {
                this.dialogPermission.permission.frontPermission.account.child.push(
                    "profile"
                );
            }
            if (this.accountConfig) {
                this.dialogPermission.permission.frontPermission.account.child.push(
                    "config"
                );
                if (
                    !this.dialogPermission.permission.frontPermission[
                        "settings"
                    ]
                ) {
                    this.dialogPermission.permission.frontPermission[
                        "settings"
                    ] = {
                        child: [],
                        function: [],
                    };
                }
            }
            if (this.accountPermission) {
                this.dialogPermission.permission.frontPermission.account.child.push(
                    "permission"
                );
            }
            if (this.accountManagement) {
                this.dialogPermission.permission.frontPermission.account.child.push(
                    "management"
                );
            }
            if (
                this.alertConfig ||
                this.brokerConfig ||
                this.serverConfig ||
                this.bookConfig ||
                this.pkTaker ||
                this.routingConfig ||
                this.copyTradeConfig ||
                this.alertConfigEdit ||
                this.lpConfig ||
                this.notificationConfig
            ) {
                this.dialogPermission.permission.frontPermission["settings"] = {
                    child: [],
                    function: [],
                };
            }
            if (this.alertConfig) {
                this.dialogPermission.permission.frontPermission.settings.child.push(
                    "alertConfig"
                );
            }
            if (this.brokerConfig) {
                this.dialogPermission.permission.frontPermission.settings.child.push(
                    "brokerConfig"
                );
            }
            if (this.serverConfig) {
                this.dialogPermission.permission.frontPermission.settings.child.push(
                    "serverConfig"
                );
            }
            if (this.bookConfig) {
                this.dialogPermission.permission.frontPermission.settings.child.push(
                    "bookConfig"
                );
            }
            if (this.pkTaker) {
                this.dialogPermission.permission.frontPermission.settings.child.push(
                    "pkTaker"
                );
            }
            if (this.routingConfig) {
                this.dialogPermission.permission.frontPermission.settings.child.push(
                    "routingConfig"
                );
            }
            if (this.copyTradeConfig) {
                this.dialogPermission.permission.frontPermission.settings.child.push(
                    "copyTradeConfig"
                );
            }
            if (this.alertConfigEdit) {
                this.dialogPermission.permission.frontPermission.settings.function.push(
                    "alertConfigEdit"
                );
            }
            if (this.lpConfig) {
                this.dialogPermission.permission.frontPermission.settings.child.push(
                    "lpConfig"
                );
            }
            if (this.notificationConfig) {
                this.dialogPermission.permission.frontPermission.settings.child.push(
                    "notificationConfig"
                );
            }
            if (this.enableK8s) {
                this.dialogPermission.permission.frontPermission["k8s"] = {
                    child: ["index"],
                };
            }
            if (this.enableCopyTrade) {
                this.dialogPermission.permission.frontPermission["copyTrade"] =
                    {
                        child: ["index"],
                    };
            }
            if (
                this.enableIBMonitor ||
                this.ibLoginDetailSummary ||
                this.ibExtraSymbolDetails ||
                this.ibLoginSummaryInfo ||
                this.ibLoginSummaryStatistic ||
                this.ibLoginSummaryTrades
            ) {
                this.dialogPermission.permission.frontPermission.ibMonitor = {
                    child: [],
                    function: [],
                };
            }
            if (this.ibLoginDetailSummary) {
                this.dialogPermission.permission.frontPermission.ibMonitor.function.push(
                    "ibLoginDetailSummary"
                );
            }
            if (this.ibExtraSymbolDetails) {
                this.dialogPermission.permission.frontPermission.ibMonitor.function.push(
                    "ibExtraSymbolDetails"
                );
            }
            if (this.ibLoginSummaryInfo) {
                this.dialogPermission.permission.frontPermission.ibMonitor.function.push(
                    "ibLoginSummaryInfo"
                );
            }
            if (this.ibLoginSummaryStatistic) {
                this.dialogPermission.permission.frontPermission.ibMonitor.function.push(
                    "ibLoginSummaryStatistic"
                );
            }
            if (this.ibLoginSummaryTrades) {
                this.dialogPermission.permission.frontPermission.ibMonitor.function.push(
                    "ibLoginSummaryTrades"
                );
            }
            if (this.enableIBMonitor) {
                this.dialogPermission.permission.frontPermission.ibMonitor.child.push(
                    "index"
                );
            }
            if (this.bookSelection) {
                this.dialogPermission.permission.frontPermission.bookSelection =
                    this.bookSelection;
            }
        },
        changeView() {
            if (this.jsonView === false) {
                if (this.isPermissionValidJson()) {
                    this.jsonView = false;
                } else {
                    this.snackBarDanger(
                        "Json is invalid, please double check the format!"
                    );
                    this.$nextTick(() => {
                        this.jsonView = true;
                    });
                }
            }
        },
        /**
         * Update permission API call
         * @return  {[type]}  [return description]
         */
        updatePermission() {
            if (this.isPermissionValidJson()) {
                if (
                    validator.commonStringValidator(
                        this.dialogPermission.permission_name
                    )
                ) {
                    this.dialogPermission.permission_name =
                        validator.cleanUpStringData(
                            this.dialogPermission.permission_name
                        );
                    this.putPermissionAction(this.dialogPermission);
                } else {
                    this.snackBarDanger(
                        "Invalid permission name, please use another one"
                    );
                }
            } else {
                this.snackBarDanger("Invalid Json syntax, please double check");
            }
        },
        createPermission() {
            if (this.isPermissionValidJson()) {
                if (
                    validator.commonStringValidator(
                        this.dialogPermission.permission_name
                    )
                ) {
                    this.dialogPermission.permission_name =
                        validator.cleanUpStringData(
                            this.dialogPermission.permission_name
                        );
                    this.postPermissionAction(this.dialogPermission);
                } else {
                    this.snackBarDanger(
                        "Invalid permission name, please use another one"
                    );
                }
            } else {
                this.snackBarDanger("Invalid Json syntax, please double check");
            }
        },
        deleteConfirmation(item) {
            this.selectedDeletion = item;
            this.popup = true;
        },
        deleteConfig() {
            this.deletePermissionAction({ id: this.selectedDeletion.id });
        },
        isPermissionValidJson() {
            try {
                if (this.dialogPermission.permission.constructor === String) {
                    this.dialogPermission.permission = JSON.parse(
                        this.dialogPermission.permission
                    );
                }
                return true;
            } catch (e) {
                console.log("Json syntax error");
                return false;
            }
        },
        onBookSelectionChange(book, value) {
            if (!!value) {
                this.bookSelection.push(book.book_name);
            } else {
                this.bookSelection = this.bookSelection.filter(
                    (name) => name !== book.book_name
                );
            }
        },
        selectAll() {
            if (this.bookSelection.length !== this.bookData.length) {
                const allBooks = this.bookData.map((item) => item.book_name);
                this.bookSelection = allBooks;
            } else {
                this.bookSelection = [];
            }
        },
    },
    mounted() {
        this.getBooksAction();
        this.getPermissionAction();
    },
};
</script>

<style scoped>
.p {
    margin: 0px;
}
</style>
